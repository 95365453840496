import React, {useCallback, useState} from "react";
import DynamicTime from "../../../components/DynamicTime/DynamicTime";
import {arr} from "../../../lib/helpers/renderhelper";
import Table, {TableActions} from "../../../components/Table/Table";
import ShowYamlPopup from "../../../components/ShowYamlPopup/ShowYamlPopup";
import {Resources} from "../../../lib/resources";
import useQuery from "../../../lib/Query/Query";
import client from "../../../lib/client";
import styles from "./ActivityTable.module.scss";
import {ManagementV1Event} from "../../../../gen/model/managementV1Event";
import {ManagementV1User} from "../../../../gen/model/managementV1User";
import {ManagementV1Team} from "../../../../gen/model/managementV1Team";
import {ManagementV1Cluster} from "../../../../gen/model/managementV1Cluster";
import Owner from "../../../components/Owner/Owner";
import {Link} from "react-router-dom";
import {displayName} from "../../../lib/helper";
import Description from "../../../components/Description/Description";
import {WarningOutlined} from "@ant-design/icons";
import {Tooltip} from "../../../components/Tooltip/Tooltip";
import { DatePicker } from 'antd';
import Select from "../../../components/Select/Select";
import {Search} from "../../../components/Search/Search";
import {Popover} from "../../../components/Popover/Popover";
const debounce = require('lodash.debounce');
const { Option } = Select;

interface Props {
    top: JSX.Element | undefined;
    
    namespace?: string;
    hideNamespace?: boolean;

    target?: string;
    hideTarget?: boolean;

    user?: string;
    hideUser?: boolean;
}

function makesSingular(resources: string | undefined): string {
    if (!resources) {
        return "";
    }
    if (resources.endsWith("ses")) {
        return resources.substring(0, resources.length - 2);
    } else if (resources.endsWith("s")) {
        return resources.substring(0, resources.length - 1);
    }
    return resources;
}

function getActivity(event: ManagementV1Event, showNamespace: boolean) {
    let activity = (event.status?.objectRef?.subresource ? event.status?.objectRef?.subresource : event.status?.verb) + "";
    if (event.status?.objectRef?.name) {
        activity += " " + makesSingular(event.status?.objectRef?.resource);
        if (activity === "profile user") {
            activity = "Update profile of user";
        }
        
        activity += " " + event.status?.objectRef?.name;
    } else {
        if (event.status?.verb === "create" && event.status.requestObject) {
            const requestObjectName = (event.status.requestObject as any).metadata?.name;
            if (requestObjectName) {
                activity += " " + makesSingular(event.status?.objectRef?.resource) + " " + requestObjectName;
            } else {
                const requestGeneratedObjectName = (event.status.requestObject as any).metadata?.generateName;
                if (requestGeneratedObjectName) {
                    activity += " " + makesSingular(event.status?.objectRef?.resource) + " with generated name '" + requestGeneratedObjectName + "'";
                } else {
                    activity += " " + event.status?.objectRef?.resource;
                }
            }
        } else {
            activity += " " + event.status?.objectRef?.resource;
        }
    }
    if (showNamespace && event.status?.objectRef?.namespace) {
        activity += " in namespace " + event.status.objectRef.namespace;
    }

    if (activity === "create selves") {
        return "Get own user info";
    }
    if (activity === "create licensetokens") {
        return "Refresh license tokens";
    }
    if (activity === "create selfsubjectaccessreviews") {
        return "Check own permissions";
    }

    return activity.charAt(0).toUpperCase() + activity.slice(1);;
}

function getTarget(requestURI: string | undefined, clusters: ManagementV1Cluster[]) {
    if (!requestURI) {
        return "Unknown";
    }

    const splitted = requestURI.split("/");
    if (splitted.length < 3 || splitted[1] !== "kubernetes") {
        return "Unknown";
    }

    if (splitted.length >= 3 && splitted[2] === "management") {
        return "Management API";
    } else if (splitted.length >= 4 && splitted[2] === "cluster") {
        const cluster = clusters.find(c => c.metadata?.name === splitted[3]);
        return <Link className={styles["link"]} to={`/clusters/details/${splitted[3]}`}>{displayName(cluster) || splitted[3]} (Connected Cluster)</Link>
    } else if (splitted.length >= 6 && splitted[2] === "virtualcluster") {
        return <Link className={styles["link"]} to={`/vclusters/${splitted[3]}/${splitted[4]}/${splitted[5]}`}>{splitted[5]} (Virtual Cluster)</Link>
    }

    return "Unknown";
}

function getTableColumns(refetch: () => Promise<void>, users: ManagementV1User[], teams: ManagementV1Team[], clusters: ManagementV1Cluster[], hideUser: boolean, hideTarget: boolean, hideNamespace: boolean) {
    let columns: any = [
        {
            title: "Activity",
            render: (event: ManagementV1Event) => {
                return <Description.Column>{getActivity(event, !hideNamespace)}</Description.Column>
            },
        },
        {
            title: <Tooltip title={"Shows if the request was successful or failed."}>
                <span>Result</span>
            </Tooltip>,
            render: (event: ManagementV1Event) => {
                if (event.status?.responseStatus?.status === "Success" || (event.status?.responseStatus?.code && event.status?.responseStatus?.code < 400)) {
                    return "Success";
                } else if (event.status?.responseStatus?.message?.includes("abort Handler")) {
                    return <span className={"color-warning"}><WarningOutlined style={{paddingRight: "5px"}} />Aborted</span>;
                }

                let message = "Code " + event.status?.responseStatus?.code;
                if (event.status?.responseStatus?.message) {
                    message = event.status?.responseStatus?.message + " (" + event.status?.responseStatus?.reason + ")";
                }

                return <Popover title={"Failure"} content={<div style={{maxWidth: "300px"}}>
                    {message}
                </div>}>
                    <span className={"color-error"}><WarningOutlined style={{paddingRight: "5px"}} />Failure</span>
                </Popover>
            },
        },
        {
            title: 'Time',
            render: (event: ManagementV1Event) => {
                return <DynamicTime useTooltip={true} timestamp={event.status?.requestReceivedTimestamp} />
            }
        },
        {
            title: 'Actions',
            width: "150px",
            render: (event: ManagementV1Event) => {
                return <TableActions className={styles["actions"]}>
                    <ShowYamlPopup className={styles["setting"]} object={event} resource={Resources.ManagementV1Event} disableAutoFolds={true} name={event.status?.auditID} refetch={refetch} canUpdate={false} />
                </TableActions>;
            }
        }];

    if (!hideTarget) {
        columns = [{
            title: <Tooltip title={"If the management instance, a connected cluster or virtual cluster was targeted."}><span>Target</span></Tooltip>,
            render: (event: ManagementV1Event) => {
                return getTarget(event.status?.requestURI, clusters);
            },
        }, ...columns];
    }
    if (!hideUser) {
        columns = [{
            title: <Tooltip title={"The user or team that caused this activity."}>
                <span>User</span>
            </Tooltip>,
            render: (event: ManagementV1Event) => {
                const username = event.status?.impersonatedUser?.username || event.status?.user.username;
                if (!username) {
                    return "Unknown";
                }

                if (username.startsWith("loft:user:")) {
                    const trimmed = username.substring("loft:user:".length);
                    const user = users.find(u => trimmed === u.metadata?.name);
                    return <Owner displayName={user?.spec?.displayName} username={user?.spec?.username} kubeName={trimmed} />
                } else if (username.startsWith("loft:team:")) {
                    const trimmed = username.substring("loft:team:".length);
                    const team = teams.find(u => trimmed === u.metadata?.name);
                    return <Owner isTeam={true} displayName={team?.spec?.displayName} username={team?.spec?.username} kubeName={trimmed} />
                }

                return <Owner kubeName={username} />;
            },
        }, ...columns];
    }

    return columns;
}

export default function ActivityTable(props: Props) {
    const [subject, setSubject] = useState<string | undefined>(props.user);
    const [target, setTarget] = useState<string | undefined>(props.target);
    const [text, setText] = useState<string | undefined>(undefined);
    const [debouncedText, setDebouncedText] = useState<string | undefined>(undefined);
    const debouncedSave = useCallback(
        debounce((nextValue: string | undefined) => setDebouncedText(nextValue), 1000),
        [], // will be created only once initially
    );
    const [startDate, setStartDate] = useState<moment.Moment | null | undefined>(undefined);
    const [endDate, setEndDate] = useState<moment.Moment | null | undefined>(undefined);
    const {data: userData} = useQuery(async () => await client.management(Resources.ManagementV1User).List(), {skip: props.hideUser});
    const {data: teamData} = useQuery(async () => await client.management(Resources.ManagementV1Team).List(), {skip: props.hideUser});
    const {data: clusterData} = useQuery(async () => await client.management(Resources.ManagementV1Cluster).List(), {skip: props.hideTarget});
    const {loading, error, data, refetch} = useQuery(async () => {
        let fieldSelector: string[] = [];
        if (subject) {
            fieldSelector.push("subject="+subject);
        }
        if (target) {
            fieldSelector.push("target="+target);
        }
        if (props.namespace) {
            fieldSelector.push("namespace="+props.namespace)
        }
        if (startDate) {
            fieldSelector.push("start="+startDate.unix())
        }
        if (endDate) {
            fieldSelector.push("end="+endDate.unix())
        }
        if (debouncedText) {
            fieldSelector.push("text="+debouncedText)
        }

        return await client.management(Resources.ManagementV1Event).List({
            fieldSelector: fieldSelector.length > 0 ? fieldSelector.join(",") : undefined,
        })
    }, {refetch: [subject, target, startDate, endDate, debouncedText]});
    if (error?.err && error.val?.message === "Internal error occurred: audit not configured") {
        return <div className={styles["not-found-wrapper"]}>
            {props.top}
            <div className={styles["not-found"]}>
                Audit is not configured. Please make sure you have auditing enabled in the <Link to={"/admin"}>admin config view</Link>. If you have just enabled it, please wait a second and refresh this view.
            </div>
        </div>
    }
    
    return <div>
        <Table className={styles["table"]}
               loading={loading}
               columns={getTableColumns(refetch, arr(userData?.items), arr(teamData?.items), arr(clusterData?.items), !!props.hideUser, !!props.hideTarget, !!props.hideNamespace)}
               dataSource={data ? arr(data.items).map(event => { return {...event, key: event.status?.auditID}}) : undefined} error={error} refetch={refetch} header={{
            hideSearch: true,
            left: <div className={styles["selectors"]}>
                <Search className={styles["search"]} fromUrl={true} placeholder={"Search"} value={text} onChange={(e) => {
                    setText(e.target.value);
                    debouncedSave(e.target.value);
                }} />
                {!props.hideUser && <Select allowClear={true}
                                            value={subject}
                                            showSearch={true}
                                            placeholder={"Select a user..."}
                                            className={styles["select"]}
                                            onChange={val => setSubject(val)}>
                    {userData?.items.map(user => <Option key={"loft:user:"+user.metadata?.name} value={"loft:user:"+user.metadata?.name}><Owner displayName={user.spec?.displayName} username={user.spec?.username} kubeName={user.metadata?.name} className={styles["option"]} isTeam={false} type={"small"} /></Option>)}
                    {teamData?.items.map(team => <Option key={"loft:team:"+team.metadata?.name} value={"loft:team:"+team.metadata?.name}><Owner displayName={team.spec?.displayName} username={team.spec?.username} kubeName={team.metadata?.name} isTeam={true} className={styles["option"]} type={"small"} /></Option>)}
                </Select>}
                {!props.hideTarget && <Select allowClear={true}
                                              value={target}
                                              showSearch={true}
                                              placeholder={"Select a target..."}
                                              className={styles["select"]}
                                              onChange={val => setTarget(val)}>
                    <Option key={"management"} value={"management"}>Management</Option>
                    {clusterData?.items.map(cluster => <Option key={"cluster:"+cluster.metadata?.name} value={"cluster:"+cluster.metadata?.name}>{displayName(cluster)}</Option>)}
                </Select>}
                <DatePicker allowClear
                            value={startDate}
                            placeholder={"Start Date"}
                            className={styles["start-date"]}
                            onChange={val => setStartDate(val)}
                            showTime />
                <DatePicker allowClear
                            value={endDate}
                            className={styles["end-date"]}
                            placeholder={"End Date"}
                            onChange={val => setEndDate(val)}
                            showTime />
            </div>,
            top: props.top
        }} />
    </div>
};
